.dashboard-layout {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  background-color: #f0f0f0;
  padding: 10px;
  min-width: 250px;
  height: 100vh;
  overflow-y: auto;
}

.content {
  flex-grow: 1;
  padding: 20px;
  height: 100vh;
  overflow-y: auto;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul ul {
  padding-left: 20px;
}

.sidebar a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  border-radius: 10px;
}

.sidebar a:hover {
  background-color: #ddd;
}

.logo-title a:hover {
  background-color: rgb(255 255 255 / 0%);
}

.logo-title {
  padding: 1rem;
}

.logo {
  flex-shrink: 0;
  height: auto;
}

.navbar-brand {
  margin-bottom: 0;
}

.sub-menu {
  display: none; /* Default state hidden */
  list-style-type: none; /* Remove bullet points */
  padding-left: 20px; /* Indent sub-menu items */
}

.sub-menu.show {
  display: block; /* Visible state */
}

#show_btn {
  height: 100%;
}

/* Welcome Message */
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh; /* Use the full height of the viewport */
  padding: 20px; /* Add some padding around the content */
}

.welcome-logo {
  max-width: 100px; /* Adjust based on your logo size */
  margin-bottom: 20px; /* Space between the logo and the welcome text */
}

.login-form,
.login-btn {
  height: 50px;
}

/* Spinner */
.spinner-inline {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px; /* Space between text and spinner */
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #007bff; /* Use a color that matches your button or brand */
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-switch .form-check-input {
  width: 4em !important;
  height: 2em;
}

.nav-item .active {
  background-color: #c2c2c28f !important;
  border-radius: 5px;
}

.nav-icon {
  margin-right: 8px;
  width: 1.25em;
  height: 1.25em;
}

/* Responsive */

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/*  */

/* Add to DashboardLayout.css */
.sidebar-hidden .sidebar {
  transform: translateX(-100%);
}

.sidebar-toggle {
  display: none !important;
}

.input-filters {
  width: 25% !important;
  margin-right: 1%;
}
.custom-background {
  background-color: #6c757d !important;
}

.react-datepicker-wrapper {
  display: block !important;
}
@media (max-width: 768px) {
  .div0,
  .div2 {
    padding: 0 !important;
    margin: 0 !important;
  }

  .card.shadow-lg {
    box-shadow: none !important;
  }
  .custom-background {
    background-color: #ffffff !important;
  }
  .table-responsive {
    border: 0;
  }

  .input-filters {
    width: 100% !important;
    margin-bottom: 2%;
  }

  .sidebar {
    position: fixed;
    z-index: 50;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar-visible .sidebar {
    transform: translateX(0);
  }

  .sidebar-toggle {
    display: block !important; /* Show the toggle button on small screens */
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 100;
    cursor: pointer;
  }

  .content {
    flex-grow: 1;
    width: 100%;
    padding-left: 20px; /* Adjust as necessary for your design */
  }
}

.pagination .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
}

th,
td {
  font-size: 14px;
}

/* code-with-copy */
.code-with-copy {
  display: flex;
  align-items: baseline;
  background-color: #f0f0f0;
  padding: 5px 5px 5px 15px;
  border-radius: 4px;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}

.code-with-copy code {
  font-family: monospace;
  font-size: 1em;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #333;
}

.copy-button:hover {
  color: #007bff;
}

button.nav-link {
  color: black;
  border: 1px solid black;
}
button.nav-link.active {
  font-weight: 500;
  color: #0a58ca !important;
}

.custom-small-btn {
  font-size: x-small !important;
  font-weight: 600 !important;
  text-align: left !important;
}
